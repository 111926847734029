<template>
  <div class="content-container">
    <PageTitle title="Status Bericht" class="page-title" />
    <FormWrapper v-if="madeAPICall" :send-form-data="validateData" class="page-form">
      <template #form-content>
        <LargeInputField
          :field-title="'Bericht'"
          :field-name="'message'"
          :type="'text'"
          :value="message"
          :placeholder="'Voer een bericht in'"
          :rules="'optional'"
        />
      </template>
      <template #button-submit>
        <button type="submit" class="pink-button submit-button">
          {{ $t("SAVE") }}
        </button>
      </template>
    </FormWrapper>
  </div>
</template>

<script>
import PageTitle from '@/components/elements/texts/PageTitle'
import FormWrapper from '@/components/yo-form/FormWrapper'
import LargeInputField from '@/components/yo-form/LargeInputField'
import ApiHandler from '@/utils/handlers/ApiHandler'
import { ref } from 'vue'

export default {
  name: 'StatusMessage',
  components: {
    PageTitle,
    FormWrapper,
    LargeInputField
  },
  setup() {
    const message = ref(null)
    const madeAPICall = ref(false)

    getMessage()
    function getMessage() {
      madeAPICall.value = false
      ApiHandler.get('global-settings/frontend-flash-message').then((response) => {
        message.value = response.data
        madeAPICall.value = true
      })
    }

    function validateData(data) {
      setMessage(data.message)
    }

    function setMessage(msg) {
      if (msg === '') {
        msg = null
      }

      const formData = new FormData()
      formData.append('message', msg)

      ApiHandler.post('cms-global-settings/set-frontend-flash-message', formData).then(() => {
        getMessage()
      })
    }

    return {
      madeAPICall,
      validateData,
      message
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/base.variables";
@import "~@/assets/css/base.mixins";

</style>
